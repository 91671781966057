
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    /**
     * The select options
     */
    options: {
      type: Array as PropType<string[] | number[]>,
      required: true,
    },
    /**
     * The current value
     */
    value: {
      type: [String, Number] as PropType<string | number>,
      required: false,
    },
    /**
     * The label for the select input
     */
    label: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * The placeholder for the select input
     */
    placeholder: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * The help text for the select input
     */
    helpText: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * If the select input is disabled
     */
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * If the value should be returned as a number
     */
    isNumber: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * If the input is required
     */
    required: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * Custom validity message
     */
    validityMessage: {
      type: String as PropType<string>,
      required: false,
    },
  },
  methods: {
    /**
     * Gets called when the user selects a option
     *
     * @param {string} value The selected value
     */
    updateValue(value: string): void {
      this.setValidityMessage("");
      this.$emit("update:value", this.isNumber ? parseInt(value) : value);
    },
    /**
     * Sets a custom validity message
     *
     * @param {string} message The message
     */
    setValidityMessage(message: string): void {
      this.$refs.input.setCustomValidity(message);
    },
  },
})
export default class Select extends Vue {}
