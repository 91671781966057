import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b99a8c5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-3 d-flex" }
const _hoisted_2 = { class: "mr-auto" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckboxInput = _resolveComponent("CheckboxInput")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tooltipText)
      ? (_openBlock(), _createBlock(_component_Tooltip, {
          key: 0,
          text: _ctx.tooltipText
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CheckboxInput, {
              checked: _ctx.isChecked,
              "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isChecked) = $event)),
              disabled: _ctx.isDisabled,
              "onUpdate:disabled": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isDisabled) = $event)),
              ariaLabel: 
          _ctx.$t(`shared.${_ctx.isChecked ? 'disable' : 'enable'}_thing`, {
            thing: _ctx.courseName,
          })
        
            }, null, 8, ["checked", "disabled", "ariaLabel"])
          ]),
          _: 1
        }, 8, ["text"]))
      : _createCommentVNode("", true),
    (!_ctx.tooltipText)
      ? (_openBlock(), _createBlock(_component_CheckboxInput, {
          key: 1,
          checked: _ctx.isChecked,
          "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isChecked) = $event)),
          disabled: _ctx.isDisabled,
          "onUpdate:disabled": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isDisabled) = $event)),
          title: 
        _ctx.$t('course-occurrence.courseStartDate') +
        ': ' +
        _ctx.formatDate(_ctx.course.courseStartDate) +
        ' | ' +
        _ctx.$t('course-occurrence.courseEndDate') +
        ': ' +
        _ctx.formatDate(_ctx.course.courseEndDate)
      ,
          ariaLabel: 
        _ctx.$t(`shared.${_ctx.isChecked ? 'disable' : 'enable'}_thing`, {
          thing: _ctx.courseName,
        })
      ,
          onUpdate: _cache[4] || (_cache[4] = (checked) => _ctx.onCourseCheckedChange(checked))
        }, null, 8, ["checked", "disabled", "title", "ariaLabel"]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.course.courseCode) + ": " + _toDisplayString(_ctx.courseName) + " ", 1),
      (_ctx.isHighlighted)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "     (" + _toDisplayString(_ctx.$t("course-occurrence.courseStartDate")) + ": " + _toDisplayString(_ctx.course.courseStartDate) + ")", 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isSupportCourse && _ctx.courseIsRecentlyEnded())
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          id: "shareBtn",
          type: "button",
          class: "btn btn-primary mb-3",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onShareButtonClicked()))
        }, _toDisplayString(_ctx.$t("share-notes.share-notes")), 1))
      : _createCommentVNode("", true)
  ]))
}